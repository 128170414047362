import { formatDate, subtractTime, addTime } from '@/utils/date.utils.ts'
import store from '@/store'

const futureDatedValuations = store.getters['auth/hasFutureDatedValuations']

export const dateRange = () => {
  const dateFormat = 'yyyy-MM-dd'
  return {
    min: formatDate(subtractTime(Date.now(), { years: 5 }), dateFormat),
    max: futureDatedValuations
      ? formatDate(addTime(Date.now(), { years: 5 }), dateFormat)
      : formatDate(new Date(), dateFormat)
  }
}

export const availableDates = () => {
  return futureDatedValuations
    ? 'Select a date up to 5 years in the past or 5 years in the future.'
    : 'Select a date up to 5 years in the past to backdate your request.'
}
