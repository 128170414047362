var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(_vm.searchComponent,_vm._b({tag:"component",on:{"change":_vm.toggleOption}},'component',{ options: _vm.options },false)),_c('form',{class:{ 'box has-background-white': _vm.$mq !== 'mobile' },on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"field",class:{ 'has-addons p-2': _vm.$mq !== 'mobile' }},[_c('div',{staticClass:"control is-expanded",class:[_vm.$mq === 'mobile' ? 'field' : 'has-icons-right pr-2']},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"},{name:"validate",rawName:"v-validate",value:(_vm.validation),expression:"validation"}],staticClass:"input is-size-6 is-soft",attrs:{"data-test":"vrm-vin-input","type":"text","placeholder":"Search by VRM or VIN","name":"value","maxlength":"17"},domProps:{"value":(_vm.value)},on:{"input":function($event){if($event.target.composing)return;_vm.value=$event.target.value}}}),(_vm.$mq !== 'mobile')?_c('span',{staticClass:"icon is-right is-size-6"},[(_vm.search.value.length > 0 && _vm.search.value.length <= 7)?_c('span',{staticClass:"heading is-marginless has-text-success is-size-7 has-text-weight-bold"},[_vm._v("VRM")]):(_vm.search.value.length > 0 && _vm.search.value.length <= 17)?_c('span',{staticClass:"heading is-marginless has-text-success is-size-7 has-text-weight-bold"},[_vm._v("VIN")]):_vm._e()]):_vm._e()]),(_vm.action !== 'vpp')?_c('div',{staticClass:"control is-expanded",class:{ field: _vm.$mq === 'mobile' }},[_c('div',{staticClass:"field has-addons columns is-gapless",class:[
            _vm.$mq === 'mobile'
              ? 'is-flex-direction-column'
              : 'is-flex-direction-row'
          ]},[(_vm.options[0].isSelected && _vm.hasCustomerReference)?_c('div',{staticClass:"control is-small is-expanded column",class:[_vm.$mq === 'mobile' ? 'mb-3' : 'mr-2']},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search.customerReference),expression:"search.customerReference"}],staticClass:"input is-small is-size-6 is-soft",attrs:{"data-test":"customerReference-input","placeholder":"Add a reference","name":"customerReference","maxlength":"30"},domProps:{"value":(_vm.search.customerReference)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.search, "customerReference", $event.target.value)}}})]):_vm._e(),_c('div',{staticClass:"control is-expanded is-medium is-flex column",class:[
              _vm.$mq === 'mobile' && _vm.hasCustomerReference ? 'mb-3' : 'mt-0'
            ],attrs:{"id":"inputs-group-mileage-date-picker"}},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.search.mileage),expression:"search.mileage",modifiers:{"number":true}},{name:"validate",rawName:"v-validate",value:('numeric|min:0|max:500000'),expression:"'numeric|min:0|max:500000'"}],staticClass:"input is-medium is-size-6 is-soft mr-2",attrs:{"data-test":"mileage-input","type":"number","placeholder":"Enter mileage","name":"mileage","min":"0","max":"500000"},domProps:{"value":(_vm.search.mileage)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.search, "mileage", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"control",class:{ 'pr-2': _vm.$mq !== 'mobile' },on:{"click":function($event){return _vm.track(false)}}},[(_vm.hasDate)?_c('span',{staticClass:"button is-medium is-soft is-outlined has-background-white",on:{"click":function($event){$event.preventDefault();return _vm.toggleFilter.apply(null, arguments)}}},[_vm._m(0)]):_vm._e()])])])]):_vm._e(),_c('button',{staticClass:"button is-size-6 is-info",class:[
          { 'is-loading': _vm.$wait.is('search') },
          { 'is-fullwidth': _vm.$mq === 'mobile' }
        ],attrs:{"type":"submit","disabled":!this.type || _vm.$wait.is('search')}},[_vm._v(" Search ")])]),_c('p',{staticClass:"is-size-7",class:{ 'px-2': _vm.$mq !== 'mobile' }},[(_vm.action !== 'vpp')?_c('span',[_vm._v(" If unspecified, we will estimate the mileage for you ")]):_c('span',[_vm._v(" ")])])]),_c('transition',{attrs:{"enter-active-class":"animated faster zoomIn","leave-active-class":"animated faster zoomOut"}},[(_vm.filterIsActive)?_c('TableFilter',{attrs:{"date-min":_vm.dateRange.min,"date-max":_vm.dateRange.max,"is-companion":true},on:{"save":function($event){_vm.search.date = $event.date},"close":_vm.toggleFilter}}):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon calendar-colour",attrs:{"data-test":"date-input-button"}},[_c('i',{staticClass:"fal fa-calendar-check"})])
}]

export { render, staticRenderFns }